var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:{
        'compset-title': true,
        'compset-title--active': _vm.isActive,
    },on:{"click":_vm.handleClick}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],ref:"nameInput",class:{
            'compset-title__input': true,
            disabled: !_vm.isEdititng,
        },attrs:{"type":"text"},domProps:{"value":(_vm.name)},on:{"input":function($event){if($event.target.composing)return;_vm.name=$event.target.value}}}),_c('span',{domProps:{"textContent":_vm._s(`(${ _vm.numberOfCompetitors })`)}}),_c('div',{staticClass:"compset-title__controls"},[(!_vm.isEdititng && _vm.isActive)?_c('button',{staticClass:"compset-title__controls__edit button",on:{"click":_vm.handleEdit}},[_c('i',{staticClass:"icon-pencil-sharp"})]):(_vm.isActive)?[_c('button',{staticClass:"compset-title__controls__confirm button",on:{"click":_vm.handleChange}},[_c('i',{staticClass:"icon-Icons_cug"})]),_c('button',{staticClass:"compset-title__controls__cancel button",on:{"click":_vm.handleClear}},[_c('i',{staticClass:"icon-X-01"})])]:_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }