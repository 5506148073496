import { render, staticRenderFns } from "./osmap.vue?vue&type=template&id=78a1c807&scoped=true&"
import script from "./osmap.vue?vue&type=script&lang=ts&"
export * from "./osmap.vue?vue&type=script&lang=ts&"
import style0 from "./osmap.vue?vue&type=style&index=0&id=78a1c807&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78a1c807",
  null
  
)

export default component.exports