
import {
    LMap, LMarker, LIcon, LPopup, LTileLayer,
} from 'vue2-leaflet';
import { Component, Vue, Prop } from 'vue-property-decorator';
import L from 'leaflet';

@Component({
    components: {
        LMap,
        LMarker,
        LIcon,
        LPopup,
        LTileLayer,
    },
})
export default class OpenStreetMap extends Vue {
    @Prop({
        type: Object,
        default: () => ({
            lat: 0,
            lng: 0,
        }),
    })
    private center!: { lat: number, lng: number };

    @Prop({
        type: Array,
        default: () => [],
    })
    public markersData!: google.maps.MarkerOptions[];

    @Prop({
        type: Number,
        default: 9,
    })
    public zoom!: number;

    private map!: L.Map | null;

    get mapCenter() {
        return [this.center.lat, this.center.lng];
    }

    onReady(map: L.Map) {
        this.map = map;
        this.$emit('ready');
    }

    calculateDistance<T extends { lat: number; lng: number }>(from: T, to: T) {
        if (!this.map) return 0;

        return this.map.distance(from, to);
    }
}
