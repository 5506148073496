
import { Component, Vue, Prop } from 'vue-property-decorator';
import WarningPopup from '@/modules/common/components/popup-warning.vue';

@Component({
    components: {
        WarningPopup,
    },
})
export default class WarningDistancePopup extends Vue {
    @Prop()
    distance!: number;

    get formatedDistance() {
        return this.distance.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 0 });
    }

    close() {
        this.$emit('close');
    }
}
